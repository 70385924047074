import type { AnswerItemDto } from "@9amhealth/openapi";
import styled from "@emotion/styled";
import clsx from "clsx";
import type { FC, ReactNode } from "react";
import React, { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import translate from "src/lib/translate";
import MultiStepFormCubit from "src/state/MultiStepFormCubit/MultiStepFormCubit";
import type { CachedObject } from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import QuestionnaireCubit from "src/state/QuestionnaireCubit/QuestionnaireCubit";
import type {
  CustomQuestionnaireAnswer,
  CustomQuestionnaireFilterAnswerOptions,
  CustomQuestionnaireResult,
  QuestionnaireState
} from "src/state/QuestionnaireCubit/QuestionnaireState";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { BlocProvider, useBloc } from "src/state/state";
import Loader from "src/ui/components/Loader/Loader";
import QuestionnaireStep from "src/ui/components/QuestionnaireStep/QuestionnaireStep";
import Translate from "src/ui/components/Translate/Translate";

interface Props {
  children?: ReactNode | undefined;
  id: string;
  answers?: AnswerItemDto[];
  hiddenFields?: CachedObject;
  useRouting?: boolean;
  disableTracking?: boolean;
  preview?: boolean;
  onSubmit?: () => Promise<boolean>;
  showThankYouStep?: boolean;
  autoSkip?: boolean;
  listAll?: boolean;
  formLike?: boolean;
  disableAutoSubmit?: boolean;
  preventLoadingUserAnswers?: boolean;
  instanceId?: string;
  resetScrollOnEachStep?: boolean;
  onStep?: (step: number, total: number) => void;
  onDataSent?: (data: CustomQuestionnaireResult) => void;
  onQuestionnaireData?: (data: QuestionnaireState) => void;
  answerOverrides?: CustomQuestionnaireAnswer[];
  filterAnswerOptions?: CustomQuestionnaireFilterAnswerOptions[];
}

const WrapAllFields = styled.div`
  label: WrapAllFields;

  > div {
    position: static !important;
  }

  nine-funnel-step {
    margin: 0 auto;
  }

  &.list-all {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    position: relative;
    min-width: calc(100vw - 4em);
    overflow-x: scroll;
    overscroll-behavior-x: contain;
    touch-action: none;
    scroll-snap-type: x mandatory;
    padding: 0 5vw;

    * {
      touch-action: none;
    }

    .form-step {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
      min-width: 90vw;
      box-shadow: var(--light-shadow);
      border-radius: var(--border-radius);

      scroll-snap-align: center;
      min-height: 100%;
    }

    nine-funnel-step {
      margin: 0 auto;
    }

    @media screen and (min-width: 768px) {
      min-width: calc(100vw - 4em - 520px);
    }
  }

  &.form-like {
    padding: 0;
    flex-direction: column;
    overflow: auto;
    width: auto;
    height: auto;
    background: var(--color-cream);

    .form-step {
      width: 100%;
      height: auto;
      min-height: auto;
      min-width: auto;
      box-shadow: none;
    }

    nine-funnel-step {
      margin: 0 0 0 1em;
      padding: 3em 1em 2em 1em;
      width: auto;
      position: relative;
      border-radius: 0;
      background: var(--bg, transparent);
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      transition: background 0.8s ease-in-out;
      z-index: 1;

      &:before {
        content: var(--text, "");
        position: absolute;
        transition: all 0.2s ease-in-out;
        border: 1px solid var(--color-cream);
        left: 1em;
        top: 1.5em;
        height: 1.8em;
        min-width: 2em;
        padding: 0 0.6em;
        display: flex;
        align-items: center;
        color: var(--text-color, var(--color-charcoal));

        font-size: 0.8rem;
        background: var(--status, var(--color-afternoon-green));
        border-radius: 0.6em;
      }

      margin-top: 0 !important;
      --funnel-step-padding: 0;
      --funnel-step-min-height: 0;
      --funnel-step-width: 100%;
      --funnel-step-text-align: left;

      .step-button,
      .step-other,
      nine-spacer {
        display: none;
      }

      .step-input {
        margin: 1.5em 0 0;
      }

      h4 {
        font-size: 1.4em;
        font-family: var(--font-family);
        font-weight: 500;
        text-align: left;
      }

      img {
        width: min(300px, 100%);
      }

      .scroll-to-me {
        opacity: 1;
        position: absolute;
        top: -4em;

        &:after,
        &:before {
          content: "";
          position: fixed;
          top: calc(var(--pos-in-document-percent) + 5px);
          left: 0;
          width: 0.9em;
          height: calc(var(--el-height-percent, 0));
          background: var(--color-afternoon-green);
          transition: all 0.2s ease-in-out;
          z-index: 1000;
        }

        &:before {
          width: 1em;
          background: transparent;
        }

        &:after {
          border: 1px solid var(--color-charcoal-60);
          /* border-radius: 6px; */
          width: 0.3em;
        }

        &:hover {
          &:after {
            width: 1em;
          }
        }
      }

      &.is-optional {
        --status: var(--color-sunrise-orange);
        --text: "Optional";

        .scroll-to-me {
          &:after {
            background: var(--color-sunrise-orange);
          }
        }
      }

      &.is-required.has-issue {
        --status: var(--color-error);
        --text: "Required";
        --text-color: white;

        .scroll-to-me {
          &:after {
            background: var(--color-error);
          }
        }
      }

      &.is-statement {
        --bg: var(--color-midmorning-pink);
        --text: "Statement";
        --status: var(--color-cream);
        border: none;
        border-radius: 0.6em;
        margin: 1em 1em 1em 2em !important;

        .step-input {
          display: none;
        }

        .scroll-to-me {
          &:after {
            background: var(--color-midmorning-pink);
          }
        }
      }

      &[data-el-active] {
        --bg: var(--color-sunrise-orange);
      }

      &:hover {
        z-index: 2;
      }
    }
  }
`;

const QuestionnaireContent: FC<Props> = ({
  useRouting,
  children,
  onSubmit,
  showThankYouStep,
  autoSkip,
  listAll,
  formLike,
  resetScrollOnEachStep,
  onStep,
  onQuestionnaireData
}) => {
  const [questionnaireState, { onComplete, setMultiStepBloc, dryRunSteps }] =
    useBloc(QuestionnaireCubit);
  const navigate = useNavigate();

  const { fields, endScreens } = questionnaireState;

  useEffect(() => {
    if (onQuestionnaireData) {
      onQuestionnaireData(questionnaireState);
    }
  }, [questionnaireState]);

  const handleSubmit = async (): Promise<boolean> => {
    await onComplete();
    await onSubmit?.();
    return false;
  };

  const shouldShowStep = useCallback(
    (field: QuestionnaireField) => {
      if (listAll) return true;
      if (formLike) return dryRunSteps.includes(field.ref);
      return false;
    },
    [dryRunSteps]
  );

  useEffect(() => {
    const currentStep = window.location.hash.replace("#", "");
    const currentIndex = dryRunSteps.indexOf(currentStep);
    onStep?.(currentIndex + 1, dryRunSteps.length);
  }, [onStep, fields, dryRunSteps]);

  const multiStepBloc = useMemo(() => {
    const bloc = new MultiStepFormCubit({
      useRouting,
      setInitialStep: !autoSkip
    });
    setMultiStepBloc(bloc);
    return bloc;
  }, [useRouting]);

  return fields.length > 0 ? (
    <BlocProvider bloc={multiStepBloc}>
      <>
        {children}
        <WrapAllFields
          className={clsx({
            "list-all": listAll,
            "form-like": formLike
          })}
        >
          {fields.map((field) => (
            <QuestionnaireStep
              resetScrollOnEachStep={resetScrollOnEachStep}
              key={field.id}
              fields={[field]}
              onFinish={onSubmit}
              alwaysShow={shouldShowStep(field)}
              validateOnInit={formLike}
            />
          ))}
          {endScreens.map((field) =>
            formLike ? null : (
              <QuestionnaireStep
                resetScrollOnEachStep={resetScrollOnEachStep}
                key={field.id}
                fields={[field]}
                onSubmit={handleSubmit}
                showThankYouStep={showThankYouStep}
              />
            )
          )}
        </WrapAllFields>
      </>
    </BlocProvider>
  ) : (
    <>
      {questionnaireState.error ? (
        <>
          <nine-heading>
            <nine-spacer s="md"></nine-spacer>
            <h2>
              {translate(`error_code`, {
                context: questionnaireState.error
              })}
            </h2>
            <p className="m0">
              <Translate msg="help.contactUs" />{" "}
              <a
                href="tel:+12029329958"
                style={{ color: "#000", fontWeight: "500" }}
              >
                (202) 932-9958
              </a>
              .
              <br />
              <Translate msg="help.emailSupport" />{" "}
              <a
                href="mailto:support@join9am.com"
                style={{ color: "#000", fontWeight: "500" }}
              >
                support@join9am.com
              </a>
              <br />
              <Translate msg="help.thankYouMessage" />
            </p>
            <nine-button
              onClick={() => navigate("/app/chat")}
              variant="fill"
              color="black"
              arrow=""
              padding="equal"
            >
              <Translate msg="close" />
            </nine-button>
          </nine-heading>
        </>
      ) : (
        <Loader grow fixed overContent active />
      )}
    </>
  );
};

const CustomQuestionnaire: FC<Props> = (props) => {
  const bloc = useMemo(() => {
    const hiddenFields = QuestionnaireCubit.parseAnswers(props.answers);
    return new QuestionnaireCubit(props.id, {
      onDataSent: props.onDataSent,
      disableAutoSubmit: props.disableAutoSubmit,
      disableTracking: props.disableTracking,
      preview: props.preview,
      autoSkip: props.autoSkip,
      instanceId: props.instanceId,
      preventLoadingUserAnswers: props.preventLoadingUserAnswers,
      hiddenFields: {
        ...hiddenFields,
        ...(props.hiddenFields ?? {})
      },
      answerOverrides: props.answerOverrides,
      filterAnswerOptions: props.filterAnswerOptions
    });
  }, [props.id, props.answers, props.hiddenFields]);

  return (
    <BlocProvider<QuestionnaireCubit> bloc={bloc}>
      <QuestionnaireContent {...props} />
    </BlocProvider>
  );
};

export default CustomQuestionnaire;
