import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID } from "src/constants/misc";
import type { QuestionnaireField } from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import QuestionnaireStepCubit from "src/state/QuestionnaireStepCubit/QuestionnaireStepCubit";
import { useBloc } from "src/state/state";
import OnEvent from "src/ui/components/OnEvent/OnEvent";

const optionValueToBgColorMap = new Map<number, string>([
  [0, "#FFD4C8"],
  [1, "#FFD4C8"],
  [2, "#FFD4C8"],
  [3, "#FFD4C8"],
  [4, "#FFD4C8"],
  [5, "#FFD4C8"],
  [6, "#FFD4C8"],
  [7, "#FFF3C7"],
  [8, "#FFF3C7"],
  [9, "#C3F5D7"],
  [10, "#C3F5D7"]
]);

const optionValueToBorderColorMap = new Map<number, string>([
  [0, "#FF7663"],
  [1, "#FF7663"],
  [2, "#FF7663"],
  [3, "#FF7663"],
  [4, "#FF7663"],
  [5, "#FF7663"],
  [6, "#FF7663"],
  [7, "#FFBD70"],
  [8, "#FFBD70"],
  [9, "#6FC696"],
  [10, "#6FC696"]
]);

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  gap: 0.5rem;
  --size: calc(var(--scale) * 4.5);
  --customMargin: calc(var(--size) * 0.08);

  @media screen and (max-width: 560px) {
    --size: 11vw;
  }

  @media screen and (min-width: 960px) {
    justify-content: space-between;
  }
`;

const OpinionScaleInput: FC<{
  field: QuestionnaireField;
  onSelect?: () => void;
}> = ({ field, onSelect }) => {
  const [selected, setSelected] = useState<number | undefined>();
  const [isNPSQuestion, setIsNPSQuestion] = useState<boolean>(false);
  const [, { questionnaireState, handleChange: handleChangeCubit }] = useBloc(
    QuestionnaireStepCubit
  );
  const formContext = useFormContext();
  const initialValue = formContext.getValues()[field.id] as number | undefined;

  const {
    start_at_one = true,
    steps = 10,
    labels = {}
  } = field.properties ?? {};

  const handleChange = (event: CustomEvent<{ value: string }>) => {
    setSelected(Number(event.detail.value));
    formContext.setValue(field.id, event.detail.value);
    handleChangeCubit(new CustomEvent("forceChange"), formContext.getValues());

    if (field.validations?.required && initialValue === undefined) {
      setTimeout(() => {
        onSelect?.();
      }, 250);
    }
  };

  useEffect(() => {
    const values = formContext.getValues();

    if (values[field.id]) {
      setSelected(Number(values[field.id]));
    }

    const currentQuestionId = questionnaireState.activeField?.id;
    if (currentQuestionId === FEEDBACK_GOOD_QUESTIONNAIRE_RATING_QUESTION_ID) {
      setIsNPSQuestion(true);
    }
  }, []);

  const npsQuestionStyles = (v: number) =>
    ({
      "--option-bg-color": optionValueToBgColorMap.get(v),
      "--option-border-color": optionValueToBorderColorMap.get(v),
      "--option-selected-border-color": "var(--color-charcoal)"
    }) as unknown as CSSStyleDeclaration;

  return (
    <nine-answer-item-list
      direction="horizontal"
      start-label={labels.left}
      end-label={labels.right}
    >
      <Options>
        {Array.from({ length: steps }, (_, i) => {
          const v = start_at_one ? i + 1 : i;
          return (
            <OnEvent
              key={String(v)}
              events={{
                nineInputChange: handleChange
              }}
            >
              {/* <Item> */}
              <nine-answer-item
                style={{
                  width: "var(--size)",
                  height: "var(--size)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  ...(isNPSQuestion ? npsQuestionStyles(v) : {})
                }}
                {...(isNPSQuestion
                  ? { "hover-only-shadow": true, "custom-border": true }
                  : {})}
                value={String(v)}
                name={String(v)}
                large
                input-type="radio"
                checked={selected === v ? "true" : "false"}
              >
                {v}
              </nine-answer-item>
            </OnEvent>
          );
        })}
      </Options>
    </nine-answer-item-list>
  );
};

export default OpinionScaleInput;
